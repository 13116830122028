import React, { Component } from "react";

import load from "little-loader";
import Guard from "@identity/guard-js/src/Guard";
import { withTranslation, Trans } from "react-i18next";

class ReCaptcha extends Component {
  render() {
    return (
      <div className="terms-text">
        <Trans i18nKey="common:recaptcha_terms_statement">
          This page is protected by reCAPTCHA and is subject to the Google
          <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>
          and
          <a href="https://www.google.com/policies/terms/">Terms of Service</a>.
        </Trans>
      </div>
    );
  }
}

function loadScript(src) {
  return new Promise((resolve, reject) => {
    load(src, (err) => {
      if (!err) {
        resolve();
      } else {
        reject(err);
      }
    });
  });
}

let loadPromise = null;
function loadReCaptcha() {
  if (!loadPromise) {
    loadPromise = loadScript(
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_API}`
    ).catch(() => {
      loadPromise = null;
    });
  }
  return loadPromise;
}

function loadReCaptchaForUnitTests() {
  loadReCaptcha();
  loadPromise = Promise.resolve();
  return loadPromise;
}

async function fetchReCaptchaToken(action) {
  await loadReCaptcha();
  return await new Promise((resolve) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_API, { action })
        .then(resolve);
    });
  });
}

async function fetchGuardToken(action) {
  await loadReCaptcha();
  return await new Guard({
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_API,
    evalPath: "/webworker/eval.js",
    powEndpoint: `${process.env.REACT_APP_IDENTITY_SERVICES_SAG_HOST}/guard/pow`,
  }).execute(action);
}

const i18nReCaptcha = withTranslation(undefined, { withRef: true })(ReCaptcha);

export {
  i18nReCaptcha as ReCaptcha,
  ReCaptcha as ReCaptchaUnwrapped,
  loadReCaptcha,
  loadReCaptchaForUnitTests,
  fetchReCaptchaToken,
  fetchGuardToken,
};
