import React from "react";
import LanguageSelector from "../../LanguageSelector";
import { ReCaptcha } from "../../Guard";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Types from "prop-types";
import Analytika from "../../../analytika";
import eventAnalytics from "../../../analytikaV2";
import HelpCenterButton from "../../common/HelpCenterButton";
import { ANALYTIKA_EVENTS, HELP_CENTER_LINK } from "../../../utils/constants";
import { GalileoContext } from "../../../galileo";
import ResetAccountModal from "../../common/ResetAccountModal";
import ButtonEnhanced from "../../common/enhanced/ButtonEnhanced";

export class NameConfirmationLoginForm extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.WELCOME_BACK_IS_IT_YOU);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.WELCOME_BACK_IS_IT_YOU,
      }
    );
    Analytika.fireEvent("Idtn_Web_NameConfirm_View");
    this.onSubmit = props.onSubmit;
    this.onNameConfirmed = props.onNameConfirmed;
    this.recoveryEndpoint = props.recoveryEndpoint;
    this.onPasswordChange = props.onPasswordChange;
    this.state = {
      isModalVisible: false,
    };
    this.formRef = React.createRef();
  }

  static contextType = GalileoContext;

  render() {
    const { isEnhancedFormEnabled } = this.context;
    return isEnhancedFormEnabled ? this.renderEnhanced() : this.renderClassic();
  }

  renderClassic() {
    const { t, error, submitting, name } = this.props;
    return (
      <form
        data-request-type="name_submit"
        onSubmit={this.onSubmit}
      >
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        <div className="help-lang-container">
          <HelpCenterButton href={HELP_CENTER_LINK.FORGET_PASSWORD} />
          <LanguageSelector />
        </div>
        <h1>{t("login:confirmation_name_title")}</h1>

        {error && (
          <div className="invalid show">
            {t("spring_security_error:" + error)}
          </div>
        )}

        <div className="material-form-field">
          <input
            type="text"
            autoComplete="off"
            spellCheck="false"
            id="name"
            value={name}
            className="confirmation-name-text"
            readOnly
          />
          <label className="material-form-field-label" htmlFor="name">
            {t("login:confirmation_name_text")}
          </label>
        </div>
        <input
          className="btn-careem"
          id="confirm-name-btn"
          type="submit"
          value={submitting ? t("common:please_wait") : t("login:yes-button")}
          disabled={submitting}
          onClick={() => this.onNameConfirmed(true)}
          name="confirm"
        />
        <input
          className="no-btn-careem"
          id="reject-name-btn"
          type="submit"
          value={submitting ? t("common:please_wait") : t("login:no-button")}
          disabled={submitting}
          onClick={() => this.onNameConfirmed(false)}
          name="reject"
        />

        <ReCaptcha />
      </form>
    );
  }

  forgotPassword() {
    Analytika.fireEvent("Idtn_Web_PasswordLogin_Forget_Clicked", {
      phonenumber: this.props.fullPhoneNumber,
    });
    window.location.href = this.recoveryEndpoint;
  }

  renderEnhanced() {
    const { t, error, submitting, name } = this.props;

    return (
      <form
        data-submit-event="Idtn_Web_NameConfirm_Submit"
        onSubmit={this.onSubmit}
        ref={(ref) => {
          this.formRef = ref;
        }}
      >
        <Helmet>
          <title>Login | Careem</title>
        </Helmet>

        <h1 className="heading-h1-express center express-common">
          {t("login:confirmation_name_title")}
        </h1>

        <label className="express-form-field-label show express-common">
          <ul>
            <li>{t("login:been_here_before")}</li>
            <li>{t("login:not_you")}</li>
          </ul>
        </label>

        <label
          className="express-form-field-label express-common"
          htmlFor="name"
        >
          {t("login:confirmation_name_text")}
        </label>
        <div className="express-form-field express-common">
          <input autoComplete="off" id="name" value={name} readOnly disabled />
        </div>

        <label
          className="express-form-field-label show express-common"
          htmlFor="password"
        >
          {t("signup:password")}
        </label>
        <div className={`express-form-field express-common ${error && "show"}`}>
          <input
            autoComplete="off"
            type="password"
            id="password"
            name="password"
            onFocus={() =>
              Analytika.fireEvent("Idtn_Web_PasswordLogin_Password_Focus", {
                phonenumber: this.props.fullPhoneNumber,
              })
            }
            onChange={this.onPasswordChange}
          />
        </div>

        {error && (
          <div className="invalid show">
            {t("spring_security_error:" + error)}
          </div>
        )}

        <ButtonEnhanced
          id="confirm-name-btn"
          className="express-form-distant-item"
          name="confirm"
          disabled={submitting}
          type="submit"
          fullWidth
          onClick={() => this.onNameConfirmed(true)}
          btnType="primary"
        >
          {submitting ? t("common:please_wait") : t("login:login-button")}
        </ButtonEnhanced>

        <div className="row express-form-distant-item center express-account-help">
          <p className="express-text-secondary express-common">
            {t("login:new_user")}
          </p>
          <ButtonEnhanced
            btnType="link"
            onClick={() => {
              this.setState({ isModalVisible: true });
            }}
          >
            {t("login:account_reset")}
          </ButtonEnhanced>
          <ResetAccountModal
            onClose={() => this.setState({ isModalVisible: false })}
            onSubmit={() => {
              this.onNameConfirmed(false);
              this.setState({ isModalVisible: false });
              this.formRef.dispatchEvent(new Event("submit"));
            }}
            visible={this.state.isModalVisible}
          />
        </div>

        <div className="row center express-account-help">
          <p className="express-text-secondary express-common">
            {t("login:forgot_password")}
          </p>
          <ButtonEnhanced
            btnType="link"
            onClick={this.forgotPassword.bind(this)}
          >
            {t("login:reset_password")}
          </ButtonEnhanced>
        </div>

        <ReCaptcha />
      </form>
    );
  }
}

NameConfirmationLoginForm.propTypes = {
  t: Types.func.isRequired,
  onNameConfirmed: Types.func.isRequired,
  onSubmit: Types.func.isRequired,
  recoveryEndpoint: Types.string,
  error: Types.string,
  submitting: Types.bool,
  name: Types.string
};
export default withTranslation()(NameConfirmationLoginForm);
