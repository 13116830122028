import React from "react";
import Types from "prop-types";
import PhoneLoginForm from "./components/PhoneLoginForm";
import { loginWithEmail, loginWithPhone } from "../gateway/LoginGateway";
import OtpView from "../otp/OtpView";
import PasswordLoginForm from "./components/PasswordLoginForm";
import RedirectToLogin, {
  CSRF_QUERY_PARAM,
  CSRF_SESSION_STORAGE_FIELD,
  RedirectToLoginEnhanced,
} from "../common/RedirectToLogin";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import NameConfirmationLoginForm from "./components/NameConfirmationLoginForm";
import { parsePhone, sanitizePhone } from "../common/PhoneNumber";
import i18n from "i18next";
import { Trans } from "react-i18next";
import EmailLoginForm from "./components/EmailLoginForm";
import AppleLoginForm from "./components/AppleLoginForm";
import GoogleLoginForm from "./components/GoogleLoginForm";
import { ARGUS_APM } from "../../apm";
import { initProfiling } from "../device";
import Galileo, { GalileoContext } from "../../galileo";
import { getErrorIdFromErrorCode } from "../../utils/errorMapping";
import Analytika from "../../analytika";
import { parseAcrValues } from "../../utils/helpers";
import {
  ANALYTICKA_EVENT_NO_INVOICE_ID,
  ANALYTIKA_EVENTS,
} from "../../utils/constants";
import BlockedUser from "../blockedUser/BlockedUser";
import eventAnalytics from "../../analytikaV2";
import { bugsnagInstance } from "../../bugsnag";

const PHONE_LOGIN_FORM_VIEW = "phone_form";
const EMAIL_LOGIN_FORM_VIEW = "email_form";
const PASSWORD_FORM_VIEW = "password_form";
const APPLE_LOGIN_FORM_VIEW = "apple_form";
const GOOGLE_LOGIN_FORM_VIEW = "google_form";
const OTP_VIEW = "otp";
const NAME_CONFIRMATION = "name_confirmation";
const REDIRECT_TO_IDP = "redirect_to_idp";
const REDIRECT_TO_SIGNUP = "redirect_to_signup";
const OTP_CHALLENGE = "otp";
const PASSWORD_CHALLENGE = "password";
const APPLE_CHALLENGE = "apple";
const GOOGLE_CHALLENGE = "google";
const CONFIRM_NAME_CHALLENGE = "confirm_name";
const REDIRECT_TO_BLOCKED_USER = "redirect_to_blocked_user";

// Errors
const UNREGISTERED_USER = "unregistered_user";
const CHALLENGE_REQUIRED = "challenge_required";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      view: PHONE_LOGIN_FORM_VIEW,
      password: "",
      otp: "",
      device: "",
      recaptcha: "",
      redirectToIdpParams: {
        fieldName: null,
        value: null,
      },
      visibleFields: {
        appleButton: true,
        googleButton: true,
      },
      flowType: "phone",
      appleIdToken: null,
      googleIdToken: null,
      clientId: null,
      csrf: sessionStorage.getItem(CSRF_SESSION_STORAGE_FIELD),
      error: null,
      invoiceId: null,
      phone: {
        countryCode: undefined,
        fullPhoneNumber: undefined,
        isValid: undefined,
        nationalPhoneNumber: undefined,
      },
      blockedErrorState: "",
      blockedErrorMsg: "",
    };
    this.processUrlParams();
    Galileo.fetchIsGoogleEnabledFlag();
  }

  static contextType = GalileoContext;

  deleteParamIfPresent(paramName, queryParams) {
    if (queryParams.has(paramName)) {
      queryParams.delete(paramName);
    }
  }

  async processUrlParams() {
    const { location, history } = this.props;
    var param = location.search;
    const {
      csrf,
      fullMobileNumber,
      clientId,
      showEmail,
      showApple,
      showGoogle,
      view,
      acr_values: acrValues,
    } = queryString.parse(param);
    const externalParams = parseAcrValues(acrValues);

    if (csrf) {
      sessionStorage.setItem(CSRF_SESSION_STORAGE_FIELD, csrf);
      this.state.csrf = csrf;
    }
    this.state.clientId = clientId;
    this.state.invoiceId =
      externalParams?.invoice_id ?? ANALYTICKA_EVENT_NO_INVOICE_ID;
    this.state.showEmail = showEmail === "true";
    if (this.state.showEmail) {
      this.state.view = EMAIL_LOGIN_FORM_VIEW;
    }
    this.state.visibleFields.appleButton = showApple === "true";
    this.state.visibleFields.googleButton = showGoogle === "true";

    if (fullMobileNumber) {
      this.state.phone = parsePhone(fullMobileNumber);
    }
    if (view) {
      this.state.view = view;
    }

    if (clientId) {
      console.log(clientId);
    }
    const queryParams = new URLSearchParams(param);
    this.deleteParamIfPresent(CSRF_QUERY_PARAM, queryParams);
    history.replace({
      search: queryParams.toString(),
    });
  }

  onPhoneChange(phone) {
    this.setState({ phone });
  }
  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onNameConfirmed(nameConfirmed) {
    if (nameConfirmed) {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
        ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
        { button_name: "yes_its_me" }
      );
      Analytika.fireEvent("Idtn_Web_NameConfirm_Approved", {
        phonenumber: this.state.phone?.fullPhoneNumber,
      });
    } else {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
        ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
        { button_name: "no_iam_new_user" }
      );
      Analytika.fireEvent("Idtn_Web_NameConfirm_Reject", {
        phonenumber: this.state.phone?.fullPhoneNumber,
      });
    }
    this.setState({ nameConfirmed: nameConfirmed });
  }

  onPasswordChange(event) {
    const value = event.target.value;
    if (value.length <= 1) {
      eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_ENTER_PASSWORD.event_name,
        ANALYTIKA_EVENTS.IDNW_ENTER_PASSWORD.event_version
      );
    }
    this.setState({ password: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({
      submitting: true,
      error: null,
    });
    if (this.state.showEmail) {
      this.login(this.loginEmailRequest.bind(this));
    } else {
      const requestType = event.target.dataset.requestType;
      if (requestType) {
        eventAnalytics.setPhoneNumber(this.state.phone?.fullPhoneNumber);
        eventAnalytics.setFlowName("phone");
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
          ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
          { button_name: "continue" }
        );
        Analytika.fireEvent(requestType, {
          phonenumber: this.state.phone?.fullPhoneNumber,
        });
        eventAnalytics.publish("pyck_tap_ck_button", null, {
          flow: "identity",
          button_name: "continue",
          screen_name: "enter_phone",
        });
      }

      this.login(this.loginPhoneRequest.bind(this), requestType);
    }
  }

  onOtpVerify(otp) {
    this.setState({ otp: otp, submitting: true });
    if (this.state.showEmail) {
      this.login(this.loginEmailRequest.bind(this));
    } else {
      this.login(this.loginPhoneRequest.bind(this));
    }
  }

  onAppleTokenChanged(props) {
    let { proofToken, error } = props;
    this.setState(props);
    if (proofToken) this.redirectToIdp(proofToken);
    else if (error) {
      this.setState({ error });
      bugsnagInstance.notify(error);
    } else this.setState({ view: REDIRECT_TO_SIGNUP });
  }

  onGoogleTokenChanged(props) {
    let { proofToken, error } = props;
    this.setState(props);
    if (proofToken) this.redirectToIdp(proofToken);
    else if (error) {
      this.setState({ error });
      bugsnagInstance.notify(error);
    } else this.setState({ view: REDIRECT_TO_SIGNUP });
  }

  handleErrors(error) {
    bugsnagInstance.notify(error);
    this.setState({
      submitting: false,
      error: error.response?.data?.message || error.message || "no response",
    });
  }

  handleUnknown400Errors(error) {
    bugsnagInstance.notify(error);
    var errorMessage = i18n.t("NO_RESPONSE");
    if (error?.additional_information?.error_code) {
      var errorCode =
        getErrorIdFromErrorCode(error?.additional_information?.error_code) ||
        error?.additional_information?.error_code;
      if (errorCode === "USER_BLOCKED") {
        this.setState({
          view: REDIRECT_TO_BLOCKED_USER,
          blockedErrorState: error?.errorCode,
          blockedErrorMsg: error?.error_description,
        });

        errorMessage = (
          <Trans i18nKey="spring_security_error:USER_BLOCKED" t={this.props.t}>
            We cannot log you in at this time.
            <a href="https://help.careem.com/hc/en-us/requests/new?reason=account_issue">
              Please contact us for help.
            </a>
          </Trans>
        );
      } else {
        errorMessage = i18n.t("spring_security_error:" + errorCode);
      }
    } else if (error?.errorCode) {
      errorMessage = i18n.t("spring_security_error:" + error?.errorCode);
    } else if (error?.error_message) {
      errorMessage = error?.error_message;
    } else if (error?.errorMessage) {
      errorMessage = error?.errorMessage;
    }
    console.log(errorMessage);
    this.setState({
      submitting: false,
      error: errorMessage,
    });
  }

  handleChallengeErrors(additionalInformation) {
    switch (additionalInformation.challenge) {
      case OTP_CHALLENGE:
        this.setState({
          submitting: false,
          view: OTP_VIEW,
        });
        break;
      case APPLE_CHALLENGE:
        this.setState({
          submitting: false,
          view: APPLE_LOGIN_FORM_VIEW,
        });
        break;
      case GOOGLE_CHALLENGE:
        this.setState({
          submitting: false,
          view: GOOGLE_LOGIN_FORM_VIEW,
        });
        break;
      case PASSWORD_CHALLENGE:
        this.setState({
          submitting: false,
          view: PASSWORD_FORM_VIEW,
        });
        break;
      case CONFIRM_NAME_CHALLENGE:
        this.setState({
          submitting: false,
          view: NAME_CONFIRMATION,
          name: additionalInformation.name,
        });
        break;
      default:
        break;
    }
  }

  login(loginRequest, requestType) {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
      ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
      {
        request_type: requestType,
      }
    );
    return loginRequest()
      .then((data) => {
        if (requestType) {
          eventAnalytics.publish(
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
            {
              request_type: requestType,
              request_status: "success",
            }
          );
          Analytika.fireEvent(`${requestType}Success`, {
            phonenumber: this.state.phone?.fullPhoneNumber,
          });
        }
        eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SUCCESS_LOGIN.event_name,
          ANALYTIKA_EVENTS.IDNW_SUCCESS_LOGIN.event_version
        );
        // INFO: this will ensure to send all event that are in staged state
        eventAnalytics.flush().then(() => {
          this.redirectToIdp(data.token);
        });
      })
      .catch((error) => {
        bugsnagInstance.notify(error);
        const errorCode = error.response?.status;
        const error_description = error.response?.data?.error;
        const additional_challenge =
          error.response?.data?.additional_information?.challenge;
        if (requestType) {
          eventAnalytics.publish(
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
            ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
            {
              request_type: requestType,
              request_status: "fail",
              error_code_string: errorCode?.toString(),
              error_description: `${error_description}_${additional_challenge}`,
            }
          );
          Analytika.fireEvent(`${requestType}Fail`, {
            phonenumber: this.state.phone?.fullPhoneNumber,
          });
        }
        if (ARGUS_APM) {
          ARGUS_APM.captureError(error);
        }
        if (error.response?.status === 400) {
          this.handle400Errors(error);
        } else {
          this.handleErrors(error);
        }
      });
  }

  async loginPhoneRequest() {
    if (!this.state.phone?.isValid) {
      const err = new Error("Please enter correct phone number.");
      bugsnagInstance.notify(err);
      throw err;
    }
    let profiling = await initProfiling().catch((ex) => {
      bugsnagInstance.notify(ex);
      console.log(ex);
    });
    return loginWithPhone(
      {
        phone_number: sanitizePhone(this.state.phone.fullPhoneNumber),
        profiling: profiling,
        otp: this.state.otp,
        password: this.state.password,
        name_confirmed: this.state.nameConfirmed,
        is_known_device: false,
      },
      this.state.clientId
    );
  }
  async loginEmailRequest() {
    let profiling = await initProfiling().catch((ex) => {
      bugsnagInstance.notify(ex);
      console.log(ex);
    });
    return loginWithEmail(
      {
        email: this.state.email,
        otp: this.state.otp,
        profiling: profiling,
        password: this.state.password,
      },
      this.state.clientId
    );
  }

  handle400Errors(error) {
    bugsnagInstance.notify(error);
    switch (error.response.data?.error) {
      case UNREGISTERED_USER:
        this.setState({
          submitting: false,
          view: REDIRECT_TO_SIGNUP,
        });
        break;
      case CHALLENGE_REQUIRED:
        this.handleChallengeErrors(error.response.data?.additional_information);
        break;
      default:
        this.handleUnknown400Errors(error.response.data);
        break;
    }
  }

  redirectToIdp(proofToken) {
    this.setState({
      redirectToIdpParams: { fieldName: "proofToken", value: proofToken },
      view: REDIRECT_TO_IDP,
    });
  }

  constructSignupEndpoint() {
    const {
      csrf,
      otp,
      phone,
      appleIdToken,
      googleIdToken,
      email,
      visibleFields,
      clientId,
    } = this.state;
    const fullMobileNumber = phone?.fullPhoneNumber;
    const searchQuery = new URLSearchParams();
    if (csrf) {
      searchQuery.append(CSRF_QUERY_PARAM, csrf);
    }
    if (otp && !email) {
      searchQuery.append("otp", otp);
    }
    if (phone?.isValid) {
      searchQuery.append("fullMobileNumber", fullMobileNumber);
    }
    if (email) {
      searchQuery.append("email", email);
    }
    if (visibleFields.appleButton) {
      searchQuery.append("showApple", visibleFields.appleButton);
    }
    if (visibleFields.googleButton) {
      searchQuery.append("showGoogle", visibleFields.googleButton);
    }
    if (appleIdToken) {
      searchQuery.append("appleIdToken", appleIdToken);
    }
    if (googleIdToken) {
      searchQuery.append("googleIdToken", googleIdToken);
    }
    if (clientId) {
      searchQuery.append("clientId", clientId);
    }
    return `/signup?${searchQuery.toString()}`;
  }

  constructRecoveryEndpointEndpoint() {
    const { otp, phone, email, clientId } = this.state;
    const fullMobileNumber = phone?.fullPhoneNumber;
    const searchQuery = new URLSearchParams();
    if (otp) {
      searchQuery.append("otp", otp);
    }
    if (clientId) {
      searchQuery.append("clientId", clientId);
    } else {
      searchQuery.append("clientId", "unknown");
    }
    if (fullMobileNumber) {
      searchQuery.append("phoneNumber", fullMobileNumber);
    } else {
      return `/login?csrf=${this.state.csrf}&clientId=${clientId}&showApple=false&showGoogle=false`;
    }
    if (email) {
      searchQuery.append("email", email);
    }
    return `/session_init?${searchQuery.toString()}`;
  }

  componentDidMount() {
    Galileo.fetchIsGoogleEnabledFlag().then((toggle) =>
      this.setGoogleButtonVisibility(
        this.state.visibleFields.googleButton && toggle
      )
    );
  }

  setGoogleButtonVisibility(toggle) {
    this.setState({
      visibleFields: { ...this.state.visibleFields, googleButton: toggle },
    });
  }

  redirectToLoginHandler() {
    this.setState({
      view: PHONE_LOGIN_FORM_VIEW,
      error: null,
      phone: "",
      otp: "",
    });
  }

  render() {
    const { error, submitting, view, visibleFields } = this.state;

    const { isEnhancedFormEnabled } = this.context;

    switch (view) {
      case PHONE_LOGIN_FORM_VIEW:
        return (
          <PhoneLoginForm
            error={error}
            submitting={submitting}
            visibleFields={visibleFields}
            onPhoneChange={this.onPhoneChange.bind(this)}
            onAppleTokenChanged={this.onAppleTokenChanged.bind(this)}
            onGoogleTokenChanged={this.onGoogleTokenChanged.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            isEnhancedFormEnabled={isEnhancedFormEnabled}
            phoneState={this.state.phone}
          />
        );
      case APPLE_LOGIN_FORM_VIEW:
        return (
          <AppleLoginForm
            error={error}
            submitting={submitting}
            onAppleTokenChanged={this.onAppleTokenChanged.bind(this)}
          />
        );
      case GOOGLE_LOGIN_FORM_VIEW:
        return (
          <GoogleLoginForm
            error={error}
            submitting={submitting}
            onGoogleTokenChanged={this.onGoogleTokenChanged.bind(this)}
          />
        );
      case EMAIL_LOGIN_FORM_VIEW:
        return (
          <EmailLoginForm
            error={error}
            submitting={submitting}
            onEmailChange={this.onEmailChange.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
          />
        );
      case OTP_VIEW:
        return (
          <OtpView
            identifier={this.getIdentifier()}
            clientId={this.state.clientId}
            onOtpVerify={this.onOtpVerify.bind(this)}
            submitError={error}
            invoiceId={this.state.invoiceId}
            isEnhancedFormEnabled={isEnhancedFormEnabled}
            onClickBack={this.redirectToLoginHandler.bind(this)}
          />
        );
      case PASSWORD_FORM_VIEW:
        return (
          <PasswordLoginForm
            error={error}
            submitting={submitting}
            signupEndpoint={this.constructSignupEndpoint()}
            recoveryEndpoint={this.constructRecoveryEndpointEndpoint()}
            onPasswordChange={this.onPasswordChange.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            fullPhoneNumber={this.state.phone?.fullPhoneNumber}
          />
        );
      case NAME_CONFIRMATION:
        return (
          <NameConfirmationLoginForm
            error={error}
            submitting={submitting}
            name={this.state.name}
            onNameConfirmed={this.onNameConfirmed.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            recoveryEndpoint={this.constructRecoveryEndpointEndpoint()}
            onPasswordChange={this.onPasswordChange.bind(this)}
          />
        );
      case REDIRECT_TO_IDP:
        return isEnhancedFormEnabled ? (
          <RedirectToLoginEnhanced
            fieldName={this.state.redirectToIdpParams.fieldName}
            value={this.state.redirectToIdpParams.value}
            csrf={this.state.csrf}
            isRegistration={false} // TODO figure out flow
          />
        ) : (
          <RedirectToLogin
            fieldName={this.state.redirectToIdpParams.fieldName}
            value={this.state.redirectToIdpParams.value}
            csrf={this.state.csrf}
          />
        );
      case REDIRECT_TO_SIGNUP:
        return <Redirect to={this.constructSignupEndpoint()} />;
      case REDIRECT_TO_BLOCKED_USER:
        return (
          <BlockedUser
            blockedErrorState={this.state.blockedErrorState}
            blockedErrorMsg={this.state.blockedErrorMsg}
            fullPhoneNumber={this.getIdentifier()}
            redirectToLoginHandler={this.redirectToLoginHandler.bind(this)}
          />
        );
      default:
        break;
    }
  }
  getIdentifier() {
    return this.state.email ?? this.state.phone.fullPhoneNumber;
  }
}

Login.propTypes = {
  t: Types.func.isRequired,
  history: Types.object.isRequired,
  location: Types.object,
};
