import { galileo } from "@galileo/galileo-web-sdk";
import { initDeviceId } from "./components/device";
import { createContext } from "react";

export const GalileoContext = createContext({
  isEnhancedFormEnabled: false,
});

class Galileo {
  constructor() {
    galileo.setTimeout(3000);
    galileo.setEnvironment(`${process.env.REACT_APP_GALILEO_ENV}`);
  }

  async fetchIsGoogleEnabledFlag() {
    return galileo.getBool(
      "onboard/identity_web_google_enabled",
      {},
      false
    );
  }

  async fetchIsEnhancedFormEnabled(clientId) {
    const deviceId = await initDeviceId();
    return galileo.getBool(
      "onboard/is_enhanced_form_enabled",
      { idp_client_id: clientId, device: deviceId },
      false
    );
  }

  async fetchWeakPasswords() {
    return galileo.getString(
      "com_careem_identity/weak_passwords",
      {},
      "123456,password,123456789,12345678,123123,1234567,1234567890,111111,pakistan,pass1478,000000,654321,112233,qwerty,qwerty123,abc123,123321,iloveyou,password1,987654321,Aa123456,121212,112233,asdf1234,Pass1234,Careem"
    );
  }

  async fetchIsOneClickSignupEnabledFlag() {
    return galileo.getBool(
      "onboard/identity_web_oneclick_signup_enabled",
      {},
      false
    );
  }
}

export default new Galileo();
