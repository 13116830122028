import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { Trans, withTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";

class AlertInfo extends React.Component {
  getMessageForAlert(operation) {
    const { t } = this.props;

    switch (operation) {
      case "LOGIN":
        return {
          title: t("alert:login_alert_title"),
          message: t("alert:login_alert_message"),
        };

      case "PROFILE_UPDATE":
        return {
          title: t("alert:profile_update_alert_title"),
          message: t("alert:profile_update_alert_message"),
        };
      default:
        break;
      //TODO: Handle unknown operation?
    }
  }

  componentDidMount() {
    document.title = "Alerts | Careem";
  }

  render() {
    const { t, alert, acknowledgeAlertCallback } = this.props;

    const alertMessage = this.getMessageForAlert(alert.operation);

    return (
      <div>
        <LanguageSelector />
        <h1>
          <WarningIcon className={"largeIcon warningColor"} />
        </h1>
        <h1>{alertMessage.title}</h1>
        <hr />
        <p style={{ textAlign: "center" }}>{alertMessage.message}</p>
        <h5>
          <Trans i18nKey="alert:alert_help" t={t}>
            If this was not you, please
            {/* <a href="#" onClick={acknowledgeAlertCallback}>
              click here
            </a> */}
            <button style={{ textDecoration: 'none', color: 'inherit', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }} onClick={acknowledgeAlertCallback}>
              click here
            </button>
            to report it.
          </Trans>
        </h5>
      </div>
    );
  }
}

export default withTranslation()(AlertInfo);
