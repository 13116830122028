export const APP_STORE_URL =
  "https://apps.apple.com/us/app/careem-rides-food-delivery/id592978487";
export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.careem.acma&hl=en&gl=US";
export const CAREEM_APP_URL = "careem://open";
export const ANALYTICKA_EVENT_NO_INVOICE_ID = "no_invoice_id_provided";
export const HELP_CENTER_LINK = {
  CODE_VERIFICATION:
    "https://help.careem.com/hc/en-us/articles/360001609587-What-if-I-didn-t-receive-a-verification-code-",
  CREATE_ACCOUNT:
    "https://help.careem.com/hc/en-us/articles/360001609507-Creating-an-account",
  FORGET_PASSWORD:
    "https://help.careem.com/hc/en-us/articles/360001592468-I-forgot-my-password",
};

export const BLOCKED_LINK = "https://help.careem.com/hc/en-us/requests/new";

export const ANALYTIKA_EVENTS = {
  PAGE: {
    BLOCKED_USER: "blocked_user",
    WELCOME_BACK_IS_IT_YOU: "welcome_back_isityou",
    PASSWORD: "password",
    WELCOME: "welcome",
    OTP_VERIFY: "otp_verify",
    BUSINESS_INVITATION: "business_invitation",
    FORGOT_PASSWORD: "forgot_password",
    SIGNUP_FORM: "signup_form",
  },
  IDNW_BUSINESS_INVITATION_SUBMIT: {
    event_name: "idnw_business_invitation_submit",
    event_version: 5,
  },
  IDNW_BUSINESS_INVITATION_RESULT: {
    event_name: "idnw_business_invitation_result",
    event_version: 5,
  },
  IDNW_ENTER_PHONE_NUMBER: {
    event_name: "idnw_enter_phone_number",
    event_version: 4,
  },
  IDNW_TAP_BUTTON: { event_name: "idnw_tap_button", event_version: 3 },
  IDNW_TAP_OPTION: { event_name: "idnw_tap_option", event_version: 3 },
  IDNW_SELECT_LANGUAGE: {
    event_name: "idnw_select_language",
    event_version: 2,
  },
  IDNW_SELECT_COUNTRY: { event_name: "idnw_select_country", event_version: 3 },
  IDNW_VIEW_PAGE: { event_name: "idnw_view_page", event_version: 4 },
  IDNW_ENTER_PASSWORD: { event_name: "idnw_enter_password", event_version: 3 },
  IDNW_SEND_WEBREQUEST: {
    event_name: "idnw_send_webrequest",
    event_version: 4,
  },
  IDNW_SUCCESS_LOGIN: { event_name: "idnw_success_login", event_version: 4 },
  IDNW_ENTER_OTP: { event_name: "idnw_enter_otp", event_version: 3 },
  IDNW_ENTER_NAME: { event_name: "idnw_enter_name", event_version: 3 },
  IDNW_SUCCESS_SIGNUP: { event_name: "idnw_success_signup", event_version: 4 },
};
